.css-1s2u09g-control {
    height: 100%;
    overflow-y: scroll !important;
    /* background-color: #f0f0f0 !important; */
    /* border: none !important; */
    /* border-color: #707070 !important; */
    border-radius: 5px !important;
    padding: 0rem 0;
    /* border-bottom: 2px solid #0000002b !important; */
}

.css-1pahdxg-control {
    padding: 0rem 0 !important;
}

.css-b62m3t-container {
    width: 100% !important;
    outline: none !important;
}

.css-1pahdxg-control {
    height: 100%;
    border-color: hsl(0, 29%, 97%) !important;
}
.css-1jqq78o-placeholder {
    font-size: 0.8rem;
}
.css-t3ipsp-control .css-13cymwt-control{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.css-1u9des2-indicatorSeparator {
    background-color: transparent !important;
}
.drag-over {
    background-color: #e9ecef;
    border-color: #adb5bd;
  }

  .css-3iigni-container {
    width: 100% !important;
  }